import { Breadcrumb } from '@/Models'
import authModule from '@/store/auth'
import BreadCrumbModule from '@/store/moduleBreadCrumb'
import { Component, Vue } from 'vue-property-decorator'
import BreadCrumb from '@/components/BreadCrumb/BreadCrumb.vue'
// import BreadCrumb from '@/components/BreadCrumbs/BreadCrumbs.vue'
@Component({
  components: {
    BreadCrumb,
  },
})
export default class SubNav extends Vue {}
